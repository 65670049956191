import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' });
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
// console.log(document.body.clientWidth, 'document.body.clientWidth ')
if (document.body.clientWidth < 769) {
  localStorage.setItem('isMobile', true)
  require('@/styles/mobile.less');
  window.isMobile = true
} else {
  console.log('电脑')
  localStorage.setItem('isMobile', false)
  require('@/styles/index.less');
  window.isMobile = false
}
// if (!flag) {
//   localStorage.setItem('isMobile', true)
//   require('@/styles/mobile.less');
// } else {
//   console.log('电脑')
//   localStorage.setItem('isMobile', false)
//   require('@/styles/index.less');
// }


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

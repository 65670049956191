import axios from 'axios'
const baseUrl = window.configObj.baseUrl
// const baseUrl = 'http://192.168.1.94:8025'
export function save (data) {
  return axios({
    url: baseUrl + '/website/save',
    method: 'post',
    data
  })
}

export function upload (data) {
  return axios({
    url: baseUrl + '/website/upload',
    method: 'post',
    data
  })
}
export function getTocket (data) {
  return axios({
    url: baseUrl + '/website/getTocket',
    method: 'post',
    data
  })
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import { save, getTocket } from '@/api'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/home.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue')
      },
      {
        path: '/releaseRequire',
        name: 'releaseRequire',
        component: () => import('../views/releaseRequire.vue')
      },
      {
        path: '/product/:id',
        name: 'product',
        component: () => import('../views/product/index.vue')
      },
      {
        path: '/technology/:id',
        name: 'technology',
        component: () => import('../views/technology/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

let startTime = Date.now()
let currentTime
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  if (to) {
    currentTime = Date.now()
    console.log('startTime = ', startTime)
    console.log('用户由 ', from.path, ' 页面 跳转到 ', to.path, ' 页面，在 ', from.path, ' 页面停留了 ', currentTime - startTime, '毫秒。转换成秒数约为：', parseInt((currentTime - startTime) / 1000))
    let uuid = localStorage.getItem('iriets@uuid')
    if (uuid) {
      console.log('已存在')
    } else {
      localStorage.setItem('iriets@uuid', uuid())
      function uuid () {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
      }
    }
    let params = {
      "UVID": localStorage.getItem('iriets@uuid'),
      "Page": from.path,
      "Time": currentTime - startTime
    }
    save(params).then(res => {
      let params1 = {
        "UVID": localStorage.getItem('iriets@uuid'),
        "Page": to.path,
      }
      save(params1).then({})
    })
    startTime = Date.now()
    console.log('======== 分割线 ========')

  }
  console.log('beforeEach', to, '=====', from)
  next()
})
router.afterEach((to, from) => {
  var config = {}
  let params = {
    Url: location.href.split('#')[0]
  }
  getTocket(params).then(res => {
    config = res.data.Data
    // alert(JSON.stringify(config))
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appId, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳
      nonceStr: config.nonceStr, // 必填，生成签名的随机串
      signature: config.signature,// 必填，签名
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
    });
    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: config.title, // 分享标题
        desc: config.desc, // 分享描述
        link: config.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: config.imgUrl, // 分享图标
        success: function () {
          // 设置成功
          console.log("设置成功updateAppMessageShareData")
        }
      })
      wx.updateTimelineShareData({
        title: config.title, // 分享标题
        link: config.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: config.imgUrl, // 分享图标
        success: function () {
          console.log("设置成功updateTimelineShareData")
          // 设置成功
        }
      })
      wx.onMenuShareTimeline({
        title: config.title, // 分享标题
        desc: config.desc, // 分享描述
        link: config.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: config.imgUrl, // 分享图标
        success: function () {
          console.log("设置成功onMenuShareTimeline")
          // 设置成功
        }
      });

      wx.onMenuShareAppMessage({
        title: config.title, // 分享标题
        link: config.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: config.imgUrl, // 分享图标
        success: function () {
          console.log("设置成功onMenuShareAppMessage")
          // 设置成功
        }
      });
    });
  })

})
export default router

<template>
  <div id="app" :class="[isMobile?'mobile':'pcweb']">
    <router-view />
    <div class="xuqiu-fixed" @click="goTo('/releaseRequire')">
      <i class="el-icon-edit-outline"></i>
      <span>发布需求</span>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.isMobile = window.isMobile;
    });
  },
  methods: {
    goTo(url) {
      this.$router.push(`${url}`);
    }
  },
  components: {}
};
</script>

<style lang="less">
@import "./styles/base.less";
#app,
body,
html {
  padding: 0px;
  margin: 0px;
  font-family: PingFang SC, helvetica neue, hiragino sans gb, arial,
    microsoft yahei ui, microsoft yahei, simsun, sans-serif;
}

.xuqiu-fixed {
  width: 60px;
  height: 70px;
  position: fixed;
  right: 20px;
  bottom: 125px;
  background: #005bac;
  color: #fff;
  border-radius: 2px;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  span {
    display: block;
    line-height: 15px;
    font-size: 12px;
    margin: 5px 0;
  }
  .el-icon-edit-outline {
    font-size: 28px;
    margin-top: 9px;
  }
}
</style>

